import { Icons, Localization } from "@material-table/core";
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    Delete,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
} from "@material-ui/icons";
import { forwardRef } from "react";

const tableIcons = {
    Add: forwardRef((props: any, ref: any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props: any, ref: any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props: any, ref: any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props: any, ref: any) => <Delete {...props} ref={ref} fontSize="small" />),
    DetailPanel: forwardRef((props: any, ref: any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props: any, ref: any) => <Edit {...props} ref={ref} fontSize="small" />),
    Export: forwardRef((props: any, ref: any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props: any, ref: any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props: any, ref: any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props: any, ref: any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props: any, ref: any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props: any, ref: any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props: any, ref: any) => (
        <Clear {...props} ref={ref} fontSize="small" />
    )),
    Search: forwardRef((props: any, ref: any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props: any, ref: any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props: any, ref: any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props: any, ref: any) => <ViewColumn {...props} ref={ref} />),
} as Icons;

const tableLocalization: Localization = {
    toolbar: {
        nRowsSelected: "Элементов выбрано: {0} ",
        searchTooltip: "Поиск",
        searchPlaceholder: "Поиск",
        showColumnsTitle: "Показать колонки",
        addRemoveColumns: "Показать или скрыть колонки",
    },
    header: {
        actions: "",
    },
    body: {
        emptyDataSourceMessage: "Ничего не найдено",
        filterRow: {
            filterTooltip: "Фильтр",
        },
        editRow: {
            deleteText: "Вы действительно хотите удалить?",
            saveTooltip: "Сохранить",
            cancelTooltip: "Отмена",
        },
        deleteTooltip: "Удалить",
        editTooltip: "Редактировать",
    },
    pagination: {
        labelRowsSelect: "",
        labelRowsPerPage: "Строк на странице",
        labelDisplayedRows: " {from}-{to} из {count}",
        firstTooltip: "Первая страница",
        previousTooltip: "Пред. страница",
        nextTooltip: "След. страница",
        lastTooltip: "Посл. страница",
    },
    grouping: {
        placeholder: "Для группировки перетащите сюда названия колонок",
        groupedBy: "Сгруппировано по:",
    },
};

export { tableIcons, tableLocalization };
