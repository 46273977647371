import { Exclude, Expose, Transform, Type } from "class-transformer";
import { User } from ".";
import dayjs from "../../utils/dayjs";
import {numToRubFormat} from '../../utils';

@Exclude()
class Shop {
    @Expose() id: number;

    @Expose() title: string;
}

@Exclude()
export class OrderItem {
    @Expose() id: number;

    @Expose() count: number;

    @Expose({ name: "sale_price" }) salePrice: number;

    @Expose({ name: "sku" }) product: any;

    @Expose({ name: "is_received" }) isReceived: boolean;

    @Expose() store: string;

    @Expose() params: any;

    @Expose( {name: "bonus_amount"}) bonusAmount: number;
}

@Exclude()
class Delivery {
    @Expose() id: number;
    @Expose() cost:number;
    @Expose() uuid: string;
}

export enum OrderStatus {
    new = 0,
    paid_up = 1,
    wait_processing = 3,
    confirmed = 4,
    canceled = 5,
    assembling = 6,
    on_way = 8,
    return = 10,
    closed = 11,
    pending_payment = 12,
    pending_product = 13,
    processing = 14,
    ready_delivery = 15,
    undelivered = 17,
    exchange = 16
}

export enum DeliveryMethod {
    undefined = 0,
    pick_up = 1,
    delivery = 2
}

export enum DeliveryServices {
    undefined = 0,
    cdek = 1,
    post_russia = 2,
    dalli = 3,
    courier = 4
}

export enum StatusColor {
    default = "white",
    yellow = "#F9F96E50",
    red = "#F9716E50",
    purple = "#BA6EF950 ",
}

@Exclude()
class Order {
    @Expose() id: number;

    @Expose({ name: "full_name" }) fullName: string;

    @Expose() email: string;

    @Expose() phone: string;

    @Expose() status: OrderStatus;

    @Expose({ name: "site_name" }) siteName: string;

    @Expose() set: number;

    @Expose() courier: any;

    @Expose()
    public static getStatusName(statusId: OrderStatus): string {
        const status: { [key in OrderStatus]: string } = {
            0: "Новый",
            1: "Оплачен",
            3: "Ожидает обработки",
            4: "Подтвержден",
            5: "Отменён",
            6: "Сборка заказа",
            8: "Доставка",
            10: "Возврат",
            11: "Закрыт",
            12: "Ожидает оплаты",
            13: "Ожидание товара",
            14: "Обработка",
            15: "Готов к доставке",
            16: "Обмен",
            17: "Не доставлен",
        };
        return status[statusId];
    }

    @Expose()
    get statusName(): string {
        return Order.getStatusName(this.status);
    }

    @Expose({ name: "return_items" }) returnItems: number[];

    @Expose({ name: "city_display" }) cityDisplay: string;

    @Expose({ name: "city_name" }) cityName: string;

    @Expose() city: number;

    @Expose({ name: "hull_building" }) hullBuilding: string;

    @Expose() index: string;

    @Expose() region: string;

    @Expose() street: string;

    @Expose() house: any;

    @Expose({ name: "apartment_office" }) apartmentOffice: string;

    @Expose({ name: "payment_method_display" }) paymentMethodDisplay: string;

    @Expose({ name: "payment_method" }) paymentMethod: any;

    @Expose({ name: "cost_of_delivery" }) costOfDelivery: number;

    
    @Expose({ name: "delivery_date" })
    @Transform(({ value }) => {
        if(dayjs(value, "DD.MM.YYYY").isValid()) {
            return dayjs(value, "DD.MM.YYYY").toDate()
        }
        return null
    })
    deliveryDate: any;

    @Expose({ name: "departure_date" })
    @Transform(({ value }) => {
        if(dayjs(value, "DD.MM.YYYY").isValid()) {
            return dayjs(value, "DD.MM.YYYY").toDate()
        }
        return null
    })
    departureDate: any;

    @Expose({ name: "order_number" }) orderNumber: string;

    @Expose() barcode: string;

    @Expose({ name: "pick_up_point" }) pickUpPoint: string;

    @Expose({ name: "comment_to_the_courier" }) commentToTheCourier: string;

    @Expose({ name: "commentary_for_client" }) commentaryForClient: string;

    @Expose({ name: "commentary_for_manager"}) commentaryForManager: string;

    @Expose({ name: "commentary_for_magazine" }) commentaryForMagazine: string;

    @Expose({ name: "delivery_method" }) deliveryMethod: number | any;

    @Expose({ name: "delivery_method_display" }) deliveryMethodDisplay: string;

    @Expose({ name: "delivery_services" }) deliveryServices: number;

    @Expose({ name: "delivery_services_display" }) deliveryServicesDisplay: string;

    @Expose({ name: "delivery_time_from" })
    @Transform(({ value }) => {
        if(dayjs(value, 'HH:mm:ss').isValid()) {
            return dayjs(value, 'HH:mm:ss').toDate();
        }
        return null
    })
    deliveryTimeFrom: any;

    @Expose({ name: "delivery_time_to" })
    @Transform(({ value }) => {
        if(dayjs(value, 'HH:mm:ss').isValid()) {
            return dayjs(value, 'HH:mm:ss').toDate();
        }
        return null
    })
    deliveryTimeTo: any;

    @Expose()
    public static deliveryDateTime(order: Order): string {
        const date = dayjs(order.deliveryDate);
        if(!date.isValid()) {
            return 'Не указано';
        }
        const deliveryDate = date.format('DD.MM.YYYY');
        const deliveryTimeFrom = dayjs(order.deliveryTimeFrom).format('HH:mm');
        const deliveryTimeTo = dayjs(order.deliveryTimeTo).format('HH:mm');
        return `${deliveryDate} ${deliveryTimeFrom}-${deliveryTimeTo}`
    }

    @Expose({ name: "promo_code" }) promoCode: string;

    @Expose({ name: "total_price" }) totalPrice: number;

    @Expose({ name: "total_paid" }) totalPaid: number;

    @Expose({ name: "balance_order" }) balanceOrder: number;

    @Expose({ name: "sum_past_orders" }) sumPastOrders: number;

    @Expose() manager: number | null;

    @Expose() logistician: number | null;

    @Expose({ name: "created_at" })
    @Transform(({ value }) => new Date(value))
    createdAt: Date;

    @Expose({ name: "updated_at" })
    @Transform(({ value }) => new Date(value))
    updatedAt: Date;

    public static dateFormat(date: Date): string {
        return dayjs(date).format("DD MMMM YYYY HH:mm");
    }

    @Expose()
    get createdAtPretty(): string {
        return Order.dateFormat(this.createdAt);
    }

    @Expose()
    @Type(() => OrderItem)
    items: OrderItem[];

    @Expose({ name: "shop" }) shopId: number;

    @Expose({ name: "amount_discount" }) amountDiscount: number;

    @Expose({ name: "shop_obj" })
    @Type(() => Shop)
    shop: Shop | any;

    @Expose({ name: "user_obj" })
    @Type(() => User)
    user: User;

    @Expose({ name: "delivery_obj" })
    @Type(() => Delivery)
    deliveryObj: Delivery

    @Expose()
    get address(): string {
        return `${this.cityName} ${this.street || ""} ${this.house || ""}`;
    }

    public static makeAddress(order: Order): string {
        return `${order.cityName} ${order.street || ""} ${order.house || ""}`
    }

    public static getStatusColor(createdAt: Date, isTaken: boolean): StatusColor {
        const diff = dayjs().diff(dayjs(createdAt), "hours");

        if (isTaken) return StatusColor.purple;

        if (diff >= 1 && diff < 6) return StatusColor.yellow;
        if (diff >= 6) return StatusColor.red;

        return StatusColor.default;
    }
}

export default Order;
