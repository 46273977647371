import { useState } from "react";

export type LoadingStatus = "inactivity" | "loading" | "loaded" | "error";

const useLoading = (initialValue: LoadingStatus = "loading") => {
    const [status, setStatus] = useState<LoadingStatus>(initialValue);
    const [error, setError] = useState<string>("");

    return {
        loadingStatus: status,
        setLoadingStatus: setStatus,
        error,
        setError,
    };
};

export default useLoading;
