import { Exclude, Expose, Transform, Type } from "class-transformer";
import dayjs from "../../utils/dayjs";

@Exclude()
class Payment {
    @Expose() id: number;

    @Expose() order: number;

    @Expose() price: number;

    @Expose() status: string;

    @Expose() notes: string;

    @Expose({ name: "payment_method" }) paymentMethod: string;

    @Expose({ name: "payment_uuid" }) paymentUuid: string;

    @Expose({ name: "date_of_payment" })
    @Transform(({ value }) => {
      const date = new Date(value)
      return dayjs(date).format("DD.MM.YYYY HH:mm")
    })
    paymentDate: string;

    @Expose({ name: "bonus_amount" }) // Поле для бонусов
    bonus_amount: number;

    @Expose() paid: number; // Поле для оплаченной суммы
}

export default Payment;
