import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { Box, Typography, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Row from '../Common/Row';
import CircularPreloader from '../Common/CircularPreloader';
import ConsignmentNote from '../Common/ConsignmentNote';
import dayjs from '../../utils/dayjs';
import api from '../../api';

const CashlessOrderDetail: React.FunctionComponent = () => {
    const { id: paramsId = null } : any = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [order, setOrder] = useState<any>(null)
    const [payments, setPayments] = useState<any[]>([]);
    const [showBtn, setShowBtn] = useState(true)
    const [loadingPayments, setLoadingPayments] = useState(true);

    const handleErrorRequest = (err) => {
        const { data } = err?.response;
        enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
    }

    useEffect(() => {
        if(paramsId && !order) {
            api.getOrderDetail(paramsId)
                .then(res => setOrder({...res}))
                .catch(err => handleErrorRequest(err))
        }
    }, [paramsId])

    useEffect(() => {
        if (paramsId) {
            console.log(`Fetching payment list for order ID: ${paramsId}`);
            setLoadingPayments(true); // Устанавливаем флаг загрузки

            api.getOrderPaymentList(paramsId)
                .then(res => {
                    console.log('Fetched payment list successfully:', res);
                    setPayments(res.results); // Извлекаем `results`
                })
                .catch(err => {
                    console.error('Error fetching payment list:', err);
                    handleErrorRequest(err);
                })
                .finally(() => setLoadingPayments(false)); // Сбрасываем флаг загрузки
        } else {
            console.warn('No order ID provided to fetch payment list.');
        }
    }, [paramsId]);

    const setNewOrderStatus = (e) => {
        api.transitionPaid(order.id)
            .then(res => {
                setShowBtn(false)
                enqueueSnackbar("Заказ успешно отправлен на сборку", { variant: "success" });
            })
            .catch(err => handleErrorRequest(err))
    }

    let totalBonusAmount = 0;
    let totalPaidAmount = 0;

    if (!loadingPayments && payments.length > 0) { // Проверка перед расчетом
        totalBonusAmount = payments.reduce((total, payment) => total + (parseFloat(payment.bonus_amount) || 0), 0);
        totalPaidAmount = payments.reduce((total, payment) => total + (parseFloat(payment.paid) || 0), 0);
    }

    return (
        <>
            {order ? (
                <>
                    <Row>
                        <Box gridColumn="span 12">
                            <Typography variant='h5'>
                                Заказ № {order?.orderNumber} {order?.shop && <>магазин {order?.shop?.title}</>}
                            </Typography>
                        </Box>
                        {order.deliveryDate ? (
                            <Box gridColumn="span 12">
                                Доставка: {dayjs(order.deliveryDate).format("DD.MM.YYYY")}&nbsp;
                                <>
                                    {order.deliveryTimeFrom ? (
                                        <>{dayjs(order.deliveryTimeFrom).format("HH:mm")}</>
                                    ) : null}
                                    <span>&nbsp;-&nbsp;</span>
                                </>
                                <>
                                    {order.deliveryTimeTo ? (
                                        <>{dayjs(order.deliveryTimeTo).format("HH:mm")}</>
                                    ) : null}
                                </>
                            </Box>
                        ) : null}
                    </Row>
                    <TableContainer sx={{ marginTop: "20px" }} component={Card}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Артикул</TableCell>
                                    <TableCell>Наименование</TableCell>
                                    <TableCell>Выдано</TableCell>
                                    <TableCell>Возврат</TableCell>
                                    <TableCell>Остаток в заказе</TableCell>
                                    <TableCell>Цена</TableCell>
                                    <TableCell>Сумма</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order?.items && order?.items.map((row) => (
                                    <TableRow key={row?.id}>
                                        <TableCell>{row?.product?.attr?.sku?.value}</TableCell>
                                        <TableCell>{row?.product?.attr?.product?.value}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{row?.count}</TableCell>
                                        <TableCell>{row?.salePrice} руб.</TableCell>
                                        <TableCell>{(Number(row?.count) * Number(row?.salePrice))} руб.</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <Row>
                        <Box gridColumn="span 12">
                            <b>Итого по заказу: {order?.totalPrice} руб.</b>
                        </Box>
                        <Box gridColumn="span 12">
                            <b>В том числе доставка: {order?.costOfDelivery} руб.</b>
                        </Box>
                        {/*<Box gridColumn="span 12">*/}
                        {/*    <b>Принята оплата в размере: {order?.totalPaid} руб.</b>*/}
                        {/*</Box>*/}
                        <Box gridColumn="span 12">
                            <b>Принята оплата в размере: {totalPaidAmount>0? totalPaidAmount : order?.totalPaid} руб.</b>
                        </Box>
                        <Box gridColumn="span 12">
                            <b>Всего списано бонусов: {totalBonusAmount} руб.</b>
                        </Box>
                    </Row>
                    <ConsignmentNote order={order}>
                        {showBtn && (
                            <Row>
                                <Box gridColumn="span 5">
                                    <Button variant='contained' onClick={setNewOrderStatus}>Товар оплачен и готов к сборке</Button>
                                </Box>
                            </Row>
                        )}
                    </ConsignmentNote>
                </>
            ) : <CircularPreloader />}
        </>
        
    )
}

export default CashlessOrderDetail;
