import React from "react";
import DataTable, { DataTableColumn, RowAction } from "../../Common/DataTable";
import { useModal, useTable } from "../../../hooks";
import api from "../../../api";
import { Payment } from "../../../api/models";
import { generatePath, useHistory } from "react-router-dom";
import { Paths } from "../../../constants";
import { TableHeadStyles, } from '../../../styles';
import {numToRubFormat} from '../../../utils';

const CashlessPaidTable: React.FC<any> = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();
    const history = useHistory();

    const handleOpenPayment: RowAction<Payment>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        const path = generatePath(Paths.CASHLESS_PAID_ORDER.path, { id: rowData.order })
        history.push(path)
    };

    const handleDelete: RowAction<Payment>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("DELETE_PAYMENT", { id: rowData.id, onUpdate: updateData });
    };

    const columns: DataTableColumn<Payment>[] = [
        {
            field: "id",
            title: "#",
            defaultSort: "desc",
        },
        {
            field: "order_number",
            title: "№ Заказа",
            render: (rowData) => rowData.order,
            sorting: false,
            defaultSort: "desc",
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "payment_method",
            title: "Оплачено/Способ оплаты",
            sorting: false,
            render: (rowData) => {
                return (
                    <div>Оплата банковской картой на сайте</div>
                );
            },
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_date",
            title: "Дата и время оплаты",
            sorting: false,
            render: (rowData) => rowData.paymentDate,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "total_price",
            title: "Сумма",
            sorting: false,
            render: (rowData) => numToRubFormat(rowData.price)
        },
        {
            field: "paid",
            title: "Оплачено",
            sorting: false,
            render: (rowData) => numToRubFormat(rowData.paid)
        },
        {
            field: "bonusAmount",
            title: "Списано бонусов",
            sorting: false,
            render: (rowData) => rowData.bonus_amount
        },
    ];

    const getPayment = (params) => api.getAllPaymentList({ ...params, status: "paid", payment_method: "ONLINE" })

    return (

        <DataTable<Payment>
            title="Оплачены по безналу"
            columns={columns}
            fetchFn={getPayment}
            sorting
            filtering={false}
            options={{
                headerStyle: { ...TableHeadStyles }
            }}
            customActions={[
                {
                    tooltip: "Открыть",
                    name: "open",
                    onClick: handleOpenPayment,
                    icon: "visibility",
                    color: "primary",
                },
                {
                    tooltip: "Удалить",
                    name: "delete",
                    onClick: handleDelete,
                    icon: "delete",
                    color: "secondary",
                },
            ]}
        />
    );
};

export default CashlessPaidTable;
