import React from "react";
import {generatePath, useHistory} from "react-router";
import {Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import DataTable, {DataTableColumn, RowAction, DataTableProps} from "../../Common/DataTable";
import {useModal, useTable} from "../../../hooks";
import {Paths} from "../../../constants";
import {Order} from "../../../api/models";
import {OrderStatus} from "../../../api/models/Order.model"
import api from "../../../api";
import dayjs from "../../../utils/dayjs";
import { TableHeadStyles, } from "../../../styles";
import {numToRubFormat} from '../../../utils';

const ReturnTable: React.FC = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();
    const history = useHistory();

    const handleConfirm: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("RETURN_POSITIVE_COMMENT", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: updateData,
        });
    };

    const handleNotConfirm: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("CLOSED_ORDER_WITH_COMMENT", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: updateData,
        });
    }

    const columns: DataTableColumn<Order>[] = [
        {
            field: "id",
            filtering: false,
            title: "#",
            defaultSort: "desc",
        },
        {
            field: "order_number",
            filtering: false,
            title: "№ Заказа",
            render: (rowData) => rowData.orderNumber,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "user_info",
            filtering: false,
            title: "Получатель",
            sorting: false,
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.fullName}</div>
                        <div>{rowData.email}</div>
                        <div>
                            <b>{rowData.phone}</b>
                        </div>
                    </div>
                );
            },
        },
        {
            field: "address",
            filtering: false,
            title: "Адрес",
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.cityDisplay}</div>
                        <div>{rowData.street}</div>
                        <div>{rowData.house} {rowData.hullBuilding} {rowData.apartmentOffice}</div>
                    </div>
                )
            }
        },
        {
            field: "status",
            filtering: false,
            title: "Статус",
            render: (rowData) => Order.getStatusName(rowData.status),
        },
        {
            field: "total_price",
            filtering: false,
            title: "Сумма товаров",
            render: (rowData) => numToRubFormat(rowData.totalPrice),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_services",
            filtering: false,
            title: "Сервис доставки",
            render: (rowData) => rowData.deliveryServicesDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_cost",
            filtering: false,
            title: "Сумма доставки",
            // render: (rowData) => numToRubFormat(rowData?.deliveryObj?.cost),
            render: (rowData) => numToRubFormat(rowData.costOfDelivery),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_date",
            filtering: false,
            title: "Дата доставки",
            render: (rowData) => {
                const date = dayjs(rowData.deliveryDate);
                if(date.isValid()) {
                    return date.format("DD.MM.YYYY")
                }
                return null
            },
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
    ];

    const getOrders = (params) => api.getAllOrders({
        ...params,
        status__in: [OrderStatus.return, OrderStatus.exchange].join(','),
    })

    const DetailPanel: DataTableProps<Order>["detailPanel"] = ({ rowData }) => {
        return (
            <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                    <TableHead>
                        <TableRow>
                            <TableCell>Артикул</TableCell>
                            <TableCell>Штрих-код</TableCell>
                            <TableCell>Склад</TableCell>
                            {/* <TableCell align="right"></TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData.items?.length ? (
                            <>
                                {rowData?.items?.map((item) => (
                                    <>
                                        {rowData.returnItems.includes(item.id) ? (
                                            <TableRow key={item.id}>
                                            <TableCell>{item?.product?.attr?.sku?.value || ""}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{item.store}</TableCell>
                                            {/* <TableCell align="right">
                                                <Button
                                                    disabled={item.isReceived}
                                                    onClick={() => handleAcceptProduct({ id: item.id })}>
                                                    {!item.isReceived ? "Принять товар" : "Товар принят"}
                                                </Button>
                                            </TableCell> */}
                                        </TableRow>
                                        ) : null}
                                    </>
                                ))}
                            </>
                        ) : (
                            <TableRow>
                                <TableCell>Нет товаров</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        )
    }

    return (
        <>
            <DataTable<Order>
                title="Заказы на возврат"
                columns={columns}
                fetchFn={getOrders}
                sorting={false}
                filtering={false}
                detailPanel={DetailPanel}
                options={{
                    headerStyle: { ...TableHeadStyles }
                }}
                customActions={[
                    {
                        tooltip: "Принять",
                        name: "send",
                        onClick: handleConfirm,
                        icon: "check",
                        color: "primary",
                    },
                    {
                        tooltip: "Не принято",
                        name: "cancel",
                        onClick: handleNotConfirm,
                        icon: "cancel",
                        color: "primary",
                    },
                ]}
            />
        </>
    );
};

export default ReturnTable;
